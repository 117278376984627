import api from '@/request/api'
import { ISystemMenu, ISystemMenuQuery } from "@/apis/systemManage/menu/types";

/**
 * 分页查询
 * @param params 查询参数
 */
export const systemMenuQueryApi = (params: ISystemMenuQuery) => api.get<ISystemMenu[]>("/admin/systemMenu/getSystemMenuList", params)

/**
 * 创建
 * @param params 查询参数
 */
export const systemMenuCreateApi = (params: ISystemMenu) => api.postJ<ISystemMenu>("/admin/systemMenu/create", params)

/**
 * 修改
 * @param params 查询参数
 */
export const systemMenuModifyApi = (params: ISystemMenu) => api.postJ<ISystemMenu>("/admin/systemMenu/modify", params)


/**
 * 删除
 * @param params 查询参数
 */
export const systemMenuRemoveApi = (id: string) => api.get("/admin/systemMenu/remove/" + id)



/**
 * 查询详情
 * @param params 查询参数
 */
export const systemMenuDetailApi = (id: string) => api.get(`/admin/systemMenu/detail/${id}`)
